import React from "react";
import "./style.scss";

import ThreeCube from "./scene";


export default function Scene() {
  return (
    <div
      className="footer scene"
      // data-scroll
      // data-scroll-speed={-7}
      
    >
      {/*<div className="canvas_wrapper">*/}
      <div className="scene__wrap">

        <ThreeCube />
        
      </div>
    </div>
  );
}
