import React from "react";
import "./styleSceneMobile.scss";

import gamepad from './assets/gamepad.png';
import hands from './assets/hands.png'
import trophy from './assets/trophy.png'



export default function SceneMobile() {
  return (
      <>
      <div className="scene-wrap-mobile">

        <div className="scene-mobile">

          <div className="scene__item --joystick">
            <div className="scene__photo-wrap">
              <img className="scene__photo" src={gamepad} alt="" />
              <div className="scene__photo-shadow"></div>
            </div>
            <div className="scene__item-content-wrap">
              <div className="scene__item-content">
                <h3 className="scene__iten-title">
                  PLAY
                </h3>
                <p className="scene__iten-description">
                  In prediction pools
                  for real crypto events
                </p>
              </div>
            </div>
          </div>

          <div className="scene__item --hands">
          <div className="scene__photo-wrap">
            <img className="scene__photo" src={hands} alt="" />
              <div className="scene__photo-shadow"></div>
          </div>
          <div className="scene__item-content-wrap">
            <div className="scene__item-content">
              <h3 className="scene__iten-title">
                HODL
              </h3>
              <p className="scene__iten-description">
                Redistribution of tokens from paper hands to diamond hands
              </p>
            </div>
          </div>
        </div>

        <div className="scene__item --trophy">
          <div className="scene__photo-wrap">
            <img className="scene__photo " src={trophy} alt="" />
              <div className="scene__photo-shadow"></div>
          </div>
          <div className="scene__item-content-wrap">
            <div className="scene__item-content">
              <h3 className="scene__iten-title">
                WIN
              </h3>
              <p className="scene__iten-description">
                Top players get pay-outs and unique nft drops
              </p>
            </div>
          </div>
        </div>



        </div>

       



      </div>

      </>
  );
}
