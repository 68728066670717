import React from "react";
import "./style.scss";

import test from '../Navbar/logo.jpg'


export default function Extender() {
  return (
    <div
      // className="footer"
        className="footer-extender"
      // data-scroll
      // data-scroll-speed={-7}

    >

      {/*<h1>Beautiful Holiday Destinations</h1>*/}
      <h2 data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="7"
          className="footer__title">
        {/*Wsb Platform*/}
      </h2>

      <div className="contact-addresses">

        <div
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-10"
            data-scroll-class="appear"
            data-scroll-repeat="true"
        >
        <div className="left-h1"
            data-scroll
            data-scroll-direction="vertical"
            data-scroll-speed="120"
            data-scroll-class="appear"
            data-scroll-repeat="true"
        >


        </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
