import React from "react";
import "./stylePhoneChart.scss";

import Phone from './phone3.svg'
// import Donut from './Donut';

import Chart from './chart.svg'

import PhoneComplete from './phone-complete.svg'


export default function PhoneChart() {
  return (
    <div
        className="phonechart-extender"
      // data-scroll
      // data-scroll-speed={-7}
    >

      <div
          // className="radial-back"
          // not sure about rolling up here
          //   data-scroll
          //  data-scroll-direction="vertical"
          //  data-scroll-speed="0.5"
          //  // data-scroll-class="appear"
          //  data-scroll-repeat="true"
          //so delete it by here if you want
      >



          <img src={PhoneComplete} style={{width: 'auto', height:'115vh', paddingTop: '10vh'}} />
              {/*contact-addresses*/}

          <h1 className="wsb__title-percentage">PERCENTAGE ALLOCATION </h1>

          {/*WORKS WITH THE CHART*/}
          {/*<Donut style={{ position:'fixed', marginTop: '700px'}}/>*/}
          {/*<img src={Phone} style={{ position:'absolute', marginTop: '-500px', marginLeft:'-40px'}} />*/}




          {/*position:'absolute', marginTop: '-500px', marginLeft:'-40px'*/}


          {/*<img src={Chart} style={{*/}
          {/*    width: '300px',*/}
          {/*    height:'auto',*/}
          {/*    marginLeft:'70px',*/}
          {/*    marginTop: '80px',*/}
          {/*    // marginBottom: '1px'*/}
          {/*}} />*/}

          {/*<img src={Phone} style={{*/}
          {/*    position:'absolute',*/}
          {/*    marginTop: '-50px',*/}
          {/*    marginLeft:'-400px',*/}
          {/*    // width: '500px',*/}
          {/*    // height:'auto',*/}
          {/*    width: 'auto',*/}
          {/*    height:'auto',*/}
          {/*    zIndex: '-1'}} />*/}

          {/*<h2 className="wsb__title">TOTAL MAXIMUM SUPPLY</h2>*/}

          {/*<h1 className="wsb__number"> 5 000 000 000</h1>*/}
          {/*<h2 className="wsb__title-currency" style={{color:'#847979'}}>$WSBT</h2>*/}

      </div>
    </div>
  );
}
