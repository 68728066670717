import React, { useContext, useEffect, useRef ,useState} from "react";
import * as THREE from "three";
import gsap from 'gsap';
import glsl from 'babel-plugin-glsl/macro';


import  "./style.scss"

var V=glsl`varying vec2 vUv;\r
#define PI 3.1415926535897932384626433832795

uniform float uScrollSpeed;\r
uniform vec2 uViewportSizes;\r
uniform float uHoverState;\r
uniform vec2 uHover;\r
uniform float uTime;

void main() {

    vec4 newPosition = modelViewMatrix * vec4(position, 1.0);

    float dist = distance(uv, uHover);

    newPosition.z += sin(newPosition.y / uViewportSizes.y * PI + PI / 2.0) * - (uScrollSpeed * 15.0);

    newPosition.z += (uHoverState * 6.0) * sin(dist * 10.0 + (uTime * 1.5));

    gl_Position = projectionMatrix * newPosition;

    vUv = uv;\r
}`,
X=glsl`uniform sampler2D uImage;

varying vec2 vUv;

void main(){\r
    gl_FragColor=texture2D(uImage,vUv);\r
}`;



const Gallery = () => {
    const cubeRef = useRef(null);
      
    
    useEffect(() => {
        if (window.innerWidth >= 1024 ) {
            let stateScrolling = {
                x:3,direction:1
                // x (0-6)
            }
        
            const CameraState = {
                initX:0,
                x: 0,
                z:9,
                zoom: 0.4, 
            
    
                left:0,
                minLimitX: null,
                maxLimitX: null,
                scrolled:false,
                deltaY: 0,
                in: false
            }
            
            const wrapperSize = {
                width:window.innerWidth ,
                height: window.innerHeight
            }
            var scene = new THREE.Scene();
           
            let camera = new THREE.PerspectiveCamera(70, wrapperSize.width / wrapperSize.height, 100, 1000);
            
            camera.position.z = CameraState.z;
            camera.position.z = 700
            // camera.position.y = 60;
            var renderer = new THREE.WebGLRenderer({  alpha: true , antialias: true});
            
            
            renderer.setSize(wrapperSize.width, wrapperSize.height);
            camera.aspect = (wrapperSize.width/ wrapperSize.height);
            renderer.shadowMap.enabled = false;
            renderer.autoClear = false;
            renderer.shadowMap.type = THREE.PCFSoftShadowMap;
            // renderer.setPixelRatio(window.devicePixelRatio);
            
    
    
            
            function cameraSet(obj1 ,  targetX){
                gsap.to(camera.position,{
                    duration:0.1,
                    x:targetX
                })
    
    
            }
                
            function render() {
                renderer.render(scene, camera);
            }
            let mouseState = {
                x:0,
                y:0
            }
            let viewport = {
                x:wrapperSize.width,y:wrapperSize.height
            }
            
            let materials = []
            let shaderMaterialNew = new THREE.ShaderMaterial(
                {
                    uniforms:{
                        uTime:{value:0},
                        uImage:{value:0},
                        uHover:{value:mouseState},
                        uHoverState:{duration:1,value:0},
                        uScrollSpeed:{value: 0},
                        uViewportSizes:{
                            value:viewport
                        }
                    },
                    fragmentShader:X,
                    vertexShader:V,
                    alphaTest:0.1,
                    transparent: true,
                }
            )
            let loaderImage2D = new THREE.TextureLoader();
            let positions =  [
                // {x:0,y:600},{x:-600,y:0},{x:600,y:0},{x:0,y:-600},{x:-600,y:-1200},{x:600,y:-1200}
            ]
            let newPositions = []
            let meshes = []
            let imgPath = [
                // '/geometry/images/RealWorldEvents.png', 
                // '/geometry/images/VerifiedData.png',
                // '/geometry/images/PredictionPools.png',
                // '/geometry/images/Metaverse.png',
                // '/geometry/images/Cross-chain.png',
                // '/geometry/images/Trustless.png'
                '/geometry/images/Frame 10663.png',
                '/geometry/images/Frame 10665.png', 
                
                
                '/geometry/images/Frame 10666.png',
    
                '/geometry/images/Frame 10664.png',
    
                '/geometry/images/Frame 10668.png',
                '/geometry/images/Frame 10667.png'
                
    
            ]
            const canvasWrapper = document.querySelector(".block__wrapper")
            for (let i = 0; i < document.querySelectorAll(".test_block").length; i++) {
                let element = document.querySelectorAll(".test_block")[i];
                let posXY = {
                    x:element.offsetLeft ,
                    y:element.offsetTop 
                }
                // console.log("verstka:",element," formula:",element.offsetTop , element.getBoundingClientRect().top - document.querySelector(".block__wrapper").getBoundingClientRect().top , element.clientTop)
                let s = shaderMaterialNew.clone()
                materials.push(s)
                newPositions.push(posXY)
                let r = loaderImage2D.load(imgPath[i])
                let plane = new THREE.PlaneGeometry(element.getBoundingClientRect().width* (927 / window.innerHeight),element.getBoundingClientRect().height* (927 / window.innerHeight) ,50,50);
                s.uniforms.uImage.value = r
                let newMesh = new THREE.Mesh(plane,s)
                
                newMesh.position.x = (newPositions[i].x - window.innerWidth/2 + element.scrollWidth/2 ) * (927 / window.innerHeight)
                newMesh.position.y = (-(newPositions[i].y - window.innerHeight/2 + element.scrollHeight/2 -  canvasWrapper.getBoundingClientRect().top )) * (927/ window.innerHeight)  
              
                meshes.push(newMesh)
                scene.add(newMesh)
                element.onmousemove = (e)=>{
                    mouseState.x = e.layerX/e.target.clientWidth
                    mouseState.y = e.layerY/e.target.clientHeight
                }
                element.onmouseover = (e)=>{
                    materials[i].uniforms.uHoverState = {duration:1,value:1}
                }
                element.onmouseout = (e)=>{
                    materials[i].uniforms.uHoverState = {duration:1,value:0}
                }
            }
    
    
            // meshYPosition()
                
            const yPosState = {
                y:1,
                up:true
            }
    
            async function meshYPosition() {
                await sleep(meshYPosition,0)
                
            }
    
            function sleep(callback,params) {
                setTimeout(()=>{
                    growY()
                    changeVector()
                    callback()
                    for (let i = 0; i < document.querySelectorAll(".test_block").length; i++) {
                        const element = document.querySelectorAll(".test_block")[i];
                        element.style.transform = `translate(calc(-50% + ${(positions[i].x)/4}% ), calc(-50% + ${-yPosState.y/4 - positions[i].y/4  }%))`
                        // console.log(element)
                    }
                    // document.querySelectorAll(".test_block")[0].style.transform = `translate(-50%, calc(-50% + ${-yPosState.y}px))`
                },params)
            }
            function growY(){
                if (yPosState.up) {
                    ++yPosState.y
                }else{
                    --yPosState.y
                }
            }
    
            function changeVector() {
                if (yPosState.y == 1050) {
                    yPosState.up = !yPosState.up
                }else if (yPosState.y == -750){
                    yPosState.up = !yPosState.up
                }
            }
            
            
            function changeZ(obj,target){
                gsap.to(obj,{
                    duration:0.2,
                    z:target
                })
            }
            function changeBend(obj,target){
                gsap.to(obj,{
                    duration:0.2,
                    value:target
                })
            }
    
            window.onwheel =(e) => {
                stateScrolling.direction = (e.wheelDelta/ Math.abs(e.wheelDelta)) 
                // console.log(canvasWrapper.getBoundingClientRect().top)
            } 
            let clock = new THREE.Clock()
            function animate () {
                for (let i = 0; i < materials.length; i++) {
                    const element = materials[i];
                    let meshElement = document.querySelectorAll(".test_block")[i];
                    element.uniforms.uTime.value = clock.getElapsedTime()*5
                    
                    if (stateScrolling.x * stateScrolling.direction > 0) {
                        // camera.position.z = 650
                        changeZ(camera.position, 650)
                    }else{
                        // camera.position.z = 700
                        changeZ(camera.position, 700)
                    }
                    let finish = stateScrolling.x * stateScrolling.direction
                    changeBend(element.uniforms.uScrollSpeed, stateScrolling.x * stateScrolling.direction)
                    // element.uniforms.uScrollSpeed.value = stateScrolling.x * stateScrolling.direction
                    
                    newPositions[i].y = meshElement.offsetTop 
                    meshes[i].position.y = ((-newPositions[i].y + window.innerHeight/2 - meshElement.scrollHeight/2 -  canvasWrapper.getBoundingClientRect().top )) * (927/ window.innerHeight) 
                }
                if (window.innerWidth >= 1024) {
                    render()
                    requestAnimationFrame(animate);
                }  
                
            };
            if (window.innerWidth >= 1024) {
                animate();
            }
            cubeRef.current.appendChild(renderer.domElement);
        }
        

	}, []);




    return (
        <>
            <section className="wrapper_of_wrappers" id="stick"
                style={{position:'relative', height:'100vh', width:'280vw'}}
            >
                <div
                    className="canvas__wrapper"
                   
                    data-scroll data-scroll-sticky data-scroll-target="#stick"
                    style={{ width:'100vw'}}
                >
                    <div className="block__wrapper"
                         data-scroll
                         data-scroll-direction="vertical"
                         data-scroll-speed="11" // 16 22
                        //  data-scroll-class="appear"
                         data-scroll-repeat="true"
                         data-scroll-target="#stick"
                    >
                        <div className="test_block" style={{ marginTop:'7.86vw' ,width:'24.5vw' ,height:'24.5vw' }} >
                            {/* <h3>Title Title</h3> */}
                        </div>
                        <div className="empty"></div>
                        <div className="test_block" style={{ width:'24.5vw' ,height:'24.5vw' }}   >
                            {/* <h3>Title Title</h3> */}
                        </div>

                        <div className="test_block" style={{ width:'24.5vw' ,height:'24.5vw', marginBottom:'-19.5vw' }}    >
                        {/* <h3>Title Title</h3> */}
                        </div>
                        <div className="test_block" style={{ width:'24.5vw' ,height:'24.5vw', marginTop:'0.31vw' }}  >
                        {/* <h3>Title Title</h3> */}
                        </div>
                        <div className="test_block" style={{ width:'24.5vw' ,height:'24.5vw',  marginBottom:'-6.27vw' }}  >
                        {/* <h3>Title Title</h3> */}
                        </div>

                        <div className="empty"></div>
                        <div className="test_block" style={{ width:'24.5vw' ,height:'24.5vw', marginTop :'12.5vw' }}  >
                        {/* <h3>Title Title</h3> */}
                        </div>
                    </div>
                    <div 
                        className="" 
                        style={{position: 'absolute', width: '100vw' , height: '100vh' ,left:'0px', top:'0px' ,pointerEvents:'none' }}  
                         ref={cubeRef}
                        
                        >
                            <div className="landing__bottom-content"
                                style={{position: 'absolute', bottom: '0px', width: '100vw'  }}
                            >
                            <div className="landing__bottom-wrap"
                            >
                                <div
                                    className="landing__bottom-left-module"
                                    style={{ marginLeft: 'auto'}}
                                    >
                                        
                                   

                                    <div className="landing__bottom-content__networks">


                                    <a rel="noreferrer" target="_blank" className="menu-item" href="https://twitter.com/wsb_sh?s=21">
                                        <div>
                                        <span style={{"--index":0, fontSize: '16px'}}>T</span>
                                        <span style={{"--index":1, fontSize: '16px'}}>W</span>
                                        <span style={{"--index":2, fontSize: '16px'}}>I</span>
                                        <span style={{"--index":3, fontSize: '16px'}}>T</span>
                                        <span style={{"--index":4, fontSize: '16px'}}>T</span>
                                        <span style={{"--index":5, fontSize: '16px'}}>E</span>
                                        <span style={{"--index":6, fontSize: '16px'}}>R</span>
                                        </div>
                                        <div className="hover-clone" style={{position: 'absolute', left:"0px",top:'0px', fontSize: '8px'}}>
                                        <span style={{"--index":0, fontSize: '16px'}}>T</span>
                                        <span style={{"--index":1, fontSize: '16px'}}>W</span>
                                        <span style={{"--index":2, fontSize: '16px'}}>I</span>
                                        <span style={{"--index":3, fontSize: '16px'}}>T</span>
                                        <span style={{"--index":4, fontSize: '16px'}}>T</span>
                                        <span style={{"--index":5, fontSize: '16px'}}>E</span>
                                        <span style={{"--index":6, fontSize: '16px'}}>R</span>
                                        </div>
                                    </a>


                                    {/*<span><a style={{textDecoration:'none', cursor:'pointer'}} target='_blank' href='https://twitter.com'>TWITTER</a></span>*/}
                                    </div>

                                    <div className="landing__bottom-content__networks">

                                    <a rel="noreferrer" target="_blank" className="menu-item" href="https://t.me/wsbsh">
                                        <div>
                                        <span style={{"--index":0, fontSize: '16px'}}>T</span>
                                        <span style={{"--index":1, fontSize: '16px'}}>E</span>
                                        <span style={{"--index":2, fontSize: '16px'}}>L</span>
                                        <span style={{"--index":3, fontSize: '16px'}}>E</span>
                                        <span style={{"--index":4, fontSize: '16px'}}>G</span>
                                        <span style={{"--index":5, fontSize: '16px'}}>R</span>
                                        <span style={{"--index":6, fontSize: '16px'}}>A</span>
                                        <span style={{"--index":7, fontSize: '16px'}}>M</span>

                                        </div>
                                        <div className="hover-clone" style={{position: 'absolute', left:"0px",top:'0px', fontSize: '8px'}}>
                                        <span style={{"--index":0, fontSize: '16px'}}>T</span>
                                        <span style={{"--index":1, fontSize: '16px'}}>E</span>
                                        <span style={{"--index":2, fontSize: '16px'}}>L</span>
                                        <span style={{"--index":3, fontSize: '16px'}}>E</span>
                                        <span style={{"--index":4, fontSize: '16px'}}>G</span>
                                        <span style={{"--index":5, fontSize: '16px'}}>R</span>
                                        <span style={{"--index":6, fontSize: '16px'}}>A</span>
                                        <span style={{"--index":7, fontSize: '16px'}}>M</span>
                                        </div>
                                    </a>


                                    {/*<span><a style={{textDecoration:'none', cursor:'pointer'}} target='_blank' href='https://twitter.com'>TELEGRAM</a></span>*/}
                                    </div>
                                    <div className="landing__bottom__separator-line"></div>
                                    <div className="landing__bottom-content__networks">

                                    <a rel="noreferrer" target="_blank" className="menu-item" href="/terms">
                                        <div>
                                        <span style={{"--index":0, fontSize: '16px'}}>L</span>
                                        <span style={{"--index":1, fontSize: '16px'}}>E</span>
                                        <span style={{"--index":2, fontSize: '16px'}}>G</span>
                                        <span style={{"--index":3, fontSize: '16px'}}>A</span>
                                        <span style={{"--index":4, fontSize: '16px'}}>L</span>
                                        </div>
                                        <div className="hover-clone" style={{position: 'absolute', left:"0px",top:'0px', fontSize: '8px'}}>
                                        <span style={{"--index":0, fontSize: '16px'}}>L</span>
                                        <span style={{"--index":1, fontSize: '16px'}}>E</span>
                                        <span style={{"--index":2, fontSize: '16px'}}>G</span>
                                        <span style={{"--index":3, fontSize: '16px'}}>A</span>
                                        <span style={{"--index":4, fontSize: '16px'}}>L</span>
                                        </div>
                                    </a>

                                    {/*<span><a style={{textDecoration:'none', cursor:'pointer'}} target='_blank' href='https://twitter.com'>TELEGRAM</a></span>*/}
                                    </div>
                                    <div className="landing__bottom__separator-line"></div>
                        <div className="landing__bottom-content__networks">

                          <a rel="noreferrer" target="_blank" className="menu-item" href="https://www.coingecko.com/en/coins/wsb-sh">
                              <div>
                                  <span style={{"--index":0, fontSize: '16px'}}>C</span>
                                  <span style={{"--index":1, fontSize: '16px'}}>O</span>
                                  <span style={{"--index":2, fontSize: '16px'}}>I</span>
                                  <span style={{"--index":3, fontSize: '16px'}}>N</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>G</span>
                                  <span style={{"--index":2, fontSize: '16px'}}>E</span>
                                  <span style={{"--index":3, fontSize: '16px'}}>C</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>K</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>O</span>
                              </div>
                              <div className="hover-clone" style={{position: 'absolute', left:"0px",top:'0px', fontSize: '16px'}}>
                                  <span style={{"--index":0, fontSize: '16px'}}>C</span>
                                  <span style={{"--index":1, fontSize: '16px'}}>O</span>
                                  <span style={{"--index":2, fontSize: '16px'}}>I</span>
                                  <span style={{"--index":3, fontSize: '16px'}}>N</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>G</span>
                                  <span style={{"--index":2, fontSize: '16px'}}>E</span>
                                  <span style={{"--index":3, fontSize: '16px'}}>C</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>K</span>
                                  <span style={{"--index":4, fontSize: '16px'}}>O</span>
                              </div>
                          </a>

                        {/*<span><a style={{textDecoration:'none', cursor:'pointer'}} target='_blank' href='https://twitter.com'>TELEGRAM</a></span>*/}
                        </div>

                                   
                                </div>
                            </div>
                            </div>
                            <h2 className="gallery__title"
                                style={{position: 'absolute' ,left:'37%', top:'50%' ,pointerEvents:'none' , transform: 'translate(-50%,-50%)' ,zIndex:'-100'}} 
                            >
                                <span
                                data-scroll
                                data-scroll-direction="horizontal"
                                data-scroll-speed="-0.2" // 7 15
                                data-scroll-class="appear"data-scroll-target="#stick"

                                style={{position: "absolute", textAlign: 'center',}}
                                >WSB.sh</span>
                                <br /> 
                                <span 
                                // style={{ paddingLeft:"40px" }}
                                data-scroll
                                data-scroll-direction="horizontal"
                                data-scroll-speed="0.2" // 7 15
                                data-scroll-class="appear"data-scroll-target="#stick"

                                style={{position: "absolute", paddingLeft:"40px", transform:'translateX(-50%)'  }}
                                >Platform</span> 
                             </h2>
                    </div>
                    
                </div>
                
            </section>
            
        </>
    );
};

export default Gallery;



