  import React, { useRef, useEffect,useState } from "react";
import "../styles/home.scss";
import Footer from "./components/Footer/index";
import Contact from "./components/Contact";
import PhoneChart from "./components/PhoneChart";
import NavBar from "./components/Navbar/index";
import LocomotiveScroll from "locomotive-scroll";
import "../../node_modules/locomotive-scroll/src/locomotive-scroll.scss";

import SceneMobile from "./components/SceneMobile";
// import LendingMobile from "./components/LendingMobile";
import Lending from './components/Lending';
import Scene from "./components/Scene";
import Extender from "./components/Extender";

import FooterMobile from "./components/FooterMobile";
import LendingMobile from "./components/LendingMobile";
import PhoneChartMobile from "./components/PhoneChartMobile";


import PageFooter from "./components/PageFooter";
import Gallery from "./components/Gallery";
import {Context} from "../Context/context"


const Index = () => {
  let [scrolling, setScrolling] = useState({x:4,direction:1 })
  const ref = useRef(null);

  useEffect(() => {
    if (ref) {
      const scroll = new LocomotiveScroll({
        el: ref.current,
        smooth: true,
        direction: "horizontal",

      });
    }
    
  }, []);
    

  return (
      <Context.Provider value={{scrolling,setScrolling}}>
        <div className="home">
          {/*Using Navbar for navigation overlay*/}
          <NavBar />



          {/*START MOBILE VERSION */}

          <div className="mobile-container">

            {/*<div style={{fontSize:'36px',marginTop:'200px', marginLeft: 'auto', marginRight:'auto', marginBottom:'auto'}}>*/}
            {/*  Mobile Version Coming Soon..</div>*/}
            <LendingMobile />

            <FooterMobile />

            <div className="mobile-separator-container-outer">
              <div className="mobile__separator landing__separator--mobile" >
                <div className="mobile__separator--container">
                  <p>WSBT – DEFLATIONARY TOKEN&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <p>STAKING PLATFORM INTEGRITY&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  {/*<p>WSBT – DEFLATIONARY TOKEN&nbsp;&nbsp;&nbsp;&nbsp;</p>*/}
                  {/*<p>STAKING PLATFORM INTEGRITY&nbsp;&nbsp;&nbsp;&nbsp;</p>*/}

                </div>
                <div className="mobile__separator--container">
                  <p>WSBT – DEFLATIONARY TOKEN&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <p>STAKING PLATFORM INTEGRITY&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  {/*<p>WSBT – DEFLATIONARY TOKEN&nbsp;&nbsp;&nbsp;&nbsp;</p>*/}
                  {/*<p>STAKING PLATFORM INTEGRITY&nbsp;&nbsp;&nbsp;&nbsp;</p>*/}
                </div>
              </div>
            </div>

            <SceneMobile />
            <PhoneChartMobile />

            <div className="mobile-separator-container-outer">
              <div className="mobile__separator landing__separator--mobile" >
                <div className="mobile__separator--container">
                  <p>WSBT – DEFLATIONARY TOKEN&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <p>STAKING PLATFORM INTEGRITY&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <p>WSBT – DEFLATIONARY TOKEN&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <p>STAKING PLATFORM INTEGRITY&nbsp;&nbsp;&nbsp;&nbsp;</p>
                </div>
                <div className="mobile__separator--container">
                  <p>WSBT – DEFLATIONARY TOKEN&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <p>STAKING PLATFORM INTEGRITY&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <p>WSBT – DEFLATIONARY TOKEN&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <p>STAKING PLATFORM INTEGRITY&nbsp;&nbsp;&nbsp;&nbsp;</p>
                </div>
              </div>
            </div>

            <Contact />
          </div>

          {/*END MOBILE VERSION*/}







          {/*START DESKTOP VERSION */}
          <div data-scroll-container ref={ref}>
            {/*className="scroll-container"*/}
            <div className="content">
              
              <Lending />
              <div>
                <div className="l__separator desktop-only">
                  <div className="separator__top"
                      style={{transform: 'translate(0%, 90.8027%) translate3d(0px, 0px, 0px) rotate(180deg) skew(360deg, 0deg)'}}
                  >
                    <p  data-scroll
                        data-scroll-direction="vertical"
                        data-scroll-speed="-7"
                        data-scroll-class="appear"
                        data-scroll-repeat="true"
                        // className="separator__text"
                    >

                      <span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span>
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}
                      {/*<span>WSBT – DEFLATIONARY TOKEN</span>*/}

                    </p>
                  </div>

                  <div className="separator__bottom"
                      style={{transform: 'translate(0%, -90.8027%)   translate3d(0px, 0px, 0px)'}}
                  >
                    <p data-scroll
                      data-scroll-direction="vertical"
                      data-scroll-speed="-7"
                      data-scroll-class="appear"
                      data-scroll-repeat="true"
                    >

                      <span>WSBT – DEFLATIONARY TOKEN</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                      <span>WSBT – DEFLATIONARY TOKEN</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                      <span>WSBT – DEFLATIONARY TOKEN</span>

                    </p>
                  </div>

                </div>
              </div>


              <div className="scene__wrap"style={{paddingTop:'0px', backgroundColor:'#000000'}} >
                <Gallery />

              </div>


              
              <div>
                <div className="l__separator desktop-only">
                  <div className="separator__top"
                      style={{transform: 'translate(0%, 90.8027%) translate3d(0px, 0px, 0px) rotate(180deg) skew(360deg, 0deg)'}}
                  >
                    <p  data-scroll
                        data-scroll-direction="vertical"
                        data-scroll-speed="-7"
                        data-scroll-class="appear"
                        data-scroll-repeat="true"
                        // className="separator__text"
                    >

                      <span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span>
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}
                      {/*<span>WSBT – DEFLATIONARY TOKEN</span>*/}
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}
                      {/*<span>WSBT – DEFLATIONARY TOKEN</span>*/}
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}
                      {/*<span>WSBT – DEFLATIONARY TOKEN</span>*/}
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}
                      {/*<span>WSBT – DEFLATIONARY TOKEN</span>*/}
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}
                      {/*<span>WSBT – DEFLATIONARY TOKEN</span>*/}
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}

                    </p>
                  </div>

                  <div className="separator__bottom"
                      style={{transform: 'translate(0%, -90.8027%)   translate3d(0px, 0px, 0px)'}}
                  >
                    <p data-scroll
                      data-scroll-direction="vertical"
                      data-scroll-speed="-7"
                      data-scroll-class="appear"
                      data-scroll-repeat="true"
                    >

                      <span>WSBT – DEFLATIONARY TOKEN</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                      <span>WSBT – DEFLATIONARY TOKEN</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                      <span>WSBT – DEFLATIONARY TOKEN</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                      <span>WSBT – DEFLATIONARY TOKEN</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                      <span>WSBT – DEFLATIONARY TOKEN</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                      <span>WSBT – DEFLATIONARY TOKEN</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                    </p>
                  </div>

                </div>
              </div>
              <div className="" style={{position:'relative'}} >
                <Scene />
                <PhoneChart />
              </div>
              
              
              <div>
                <div className="l__separator desktop-only">
                  <div className="separator__top"
                      style={{transform: 'translate(0%, 90.8027%) translate3d(0px, 0px, 0px) rotate(180deg) skew(360deg, 0deg)'}}
                  >
                    <p  data-scroll
                        data-scroll-direction="vertical"
                        data-scroll-speed="-7"
                        data-scroll-class="appear"
                        data-scroll-repeat="true"
                        // className="separator__text"
                    >

                      <span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span>
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}
                      {/*<span>WSBT – DEFLATIONARY TOKEN</span>*/}
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}
                      {/*<span>WSBT – DEFLATIONARY TOKEN</span>*/}
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}
                      {/*<span>WSBT – DEFLATIONARY TOKEN</span>*/}
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}
                      {/*<span>WSBT – DEFLATIONARY TOKEN</span>*/}
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}
                      {/*<span>WSBT – DEFLATIONARY TOKEN</span>*/}
                      {/*<span>STAKING PLATFORM INTEGRITY</span>*/}

                    </p>
                  </div>

                  <div className="separator__bottom"
                      style={{transform: 'translate(0%, -90.8027%)   translate3d(0px, 0px, 0px)'}}
                  >
                    <p data-scroll
                      data-scroll-direction="vertical"
                      data-scroll-speed="-7"
                      data-scroll-class="appear"
                      data-scroll-repeat="true"
                    >

                      <span>WSBT – DEFLATIONARY TOKEN</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span><span>WSBT – DEFLATIONARY TOKEN</span><span>STAKING PLATFORM INTEGRITY</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                      <span>WSBT – DEFLATIONARY TOKEN</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                      <span>WSBT – DEFLATIONARY TOKEN</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                      <span>WSBT – DEFLATIONARY TOKEN</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                      <span>WSBT – DEFLATIONARY TOKEN</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                      <span>WSBT – DEFLATIONARY TOKEN</span>
                      <span>STAKING PLATFORM INTEGRITY</span>
                    </p>
                  </div>

                </div>
              </div>
              <Contact />
            </div>
          </div>

          {/*<PageFooter />*/}

        </div>
      </Context.Provider>
  );
};
export default Index;
