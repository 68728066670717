import React from "react";
import "./styleFooterMobile.scss";

import test from '../Navbar/logo.jpg'


//
// import crosschain from '../Footer/images/crosschain.png';
// import metaverse from '../Footer/images/Metaverse.png';
// import predPools from '../Footer/images/Pred.pools.png';
// import realWorld from '../Footer/images/Real world events.png';
// import trustless from '../Footer/images/trustless.png';
// import verified from '../Footer/images/verified-data.png';




import realWorldEvents from '../Footer/images/RealWorldEvents.png'
import predictionPools from '../Footer/images/PredictionPools.png'

import metaverseReady from  '../Footer/images/Metaverse.png'
import trustless from '../Footer/images/trustless.png'

import verifiedDataFeeds from '../Footer/images/verifiedData.png'
import crossChain from      '../Footer/images/cross-chain.png'



export default function FooterMobile() {
  return (
    <div
      className="footer-mobile"
      // data-scroll
      // data-scroll-speed={-7}
    >

      <h2 className="footer-mobile__title">
        WSB Platform
      </h2>

      {/*<div className="gallery-overlay-mobile">*/}
        <div className="gallery-mobile">

          <div style={{width:"300px", height:"auto"}}>
            {/*<h1 className="h1-mobile"> Real World Events</h1>*/}
            <img src='/geometry/images/Frame 10663.png' style={{width:"300px", height:"auto"}}/>
          </div>

          <div style={{width:"300px", height:"auto"}}>
            {/*<h1 className="h1-mobile">Trustless</h1>*/}
            <img src='/geometry/images/Frame 10666.png' style={{width:"300px", height:"auto"}}/>
          </div>


          <div style={{width:"300px", height:"auto"}}>
            {/*<h1 className="h1-mobile">Prediction Pools</h1>*/}
            <img src='/geometry/images/Frame 10664.png' style={{width:"300px", height:"auto"}}/>
          </div>

          <div style={{width:"300px", height:"auto"}}>
            {/*<h1 className="h1-mobile">Metaverse Ready</h1>*/}
            <img src='/geometry/images/Frame 10667.png' style={{width:"300px", height:"auto"}}/>
          </div>


          <div style={{width:"300px", height:"auto"}}>
            {/*<h1 className="h1-mobile">Verified Data Feeds</h1>*/}
            <img src='/geometry/images/Frame 10665.png' style={{width:"300px", height:"auto"}}/>
          </div>

          <div style={{width:"300px", height:"auto"}}>
            {/*<h1 className="h1-mobile">Cross-Chain</h1>*/}
            <img src='/geometry/images/Frame 10668.png' style={{width:"300px", height:"auto"}}/>
          </div>



      </div>
    </div>
  );
}
