import React, { useEffect, useRef, useState  } from "react";
import * as THREE from "three";
import "./styleLendingMobile.scss";
// import arrow from './arrow-right.svg';
// import Typer from "./typer";
import { GUI } from "lil-gui"
import { EffectComposer  } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import glsl from 'babel-plugin-glsl/macro';

let fragment = glsl`uniform vec2 uResolution;\r
uniform float uTime;

varying vec2 vUv;

const int AMOUNT=10;

vec3 mod289(vec3 x)\r
{\r
    return x-floor(x*(1./289.))*289.;\r
}

vec4 mod289(vec4 x)\r
{\r
    return x-floor(x*(1./289.))*289.;\r
}

vec4 permute(vec4 x)\r
{\r
    return mod289(((x*34.)+1.)*x);\r
}

vec4 taylorInvSqrt(vec4 r)\r
{\r
    return 1.79284291400159-.85373472095314*r;\r
}

vec3 fade(vec3 t){\r
    return t*t*t*(t*(t*6.-15.)+10.);\r
}

float pnoise(vec3 P,vec3 rep)\r
{\r
    vec3 Pi0=mod(floor(P),rep);
    vec3 Pi1=mod(Pi0+vec3(1.),rep);
    Pi0=mod289(Pi0);\r
    Pi1=mod289(Pi1);\r
    vec3 Pf0=fract(P);
    vec3 Pf1=Pf0-vec3(1.);
    vec4 ix=vec4(Pi0.x,Pi1.x,Pi0.x,Pi1.x);\r
    vec4 iy=vec4(Pi0.yy,Pi1.yy);\r
    vec4 iz0=Pi0.zzzz;\r
    vec4 iz1=Pi1.zzzz;\r
    \r
    vec4 ixy=permute(permute(ix)+iy);\r
    vec4 ixy0=permute(ixy+iz0);\r
    vec4 ixy1=permute(ixy+iz1);\r
    \r
    vec4 gx0=ixy0*(1./7.);\r
    vec4 gy0=fract(floor(gx0)*(1./7.))-.5;\r
    gx0=fract(gx0);\r
    vec4 gz0=vec4(.5)-abs(gx0)-abs(gy0);\r
    vec4 sz0=step(gz0,vec4(0.));\r
    gx0-=sz0*(step(0.,gx0)-.5);\r
    gy0-=sz0*(step(0.,gy0)-.5);\r
    \r
    vec4 gx1=ixy1*(1./7.);\r
    vec4 gy1=fract(floor(gx1)*(1./7.))-.5;\r
    gx1=fract(gx1);\r
    vec4 gz1=vec4(.5)-abs(gx1)-abs(gy1);\r
    vec4 sz1=step(gz1,vec4(0.));\r
    gx1-=sz1*(step(0.,gx1)-.5);\r
    gy1-=sz1*(step(0.,gy1)-.5);\r
    \r
    vec3 g000=vec3(gx0.x,gy0.x,gz0.x);\r
    vec3 g100=vec3(gx0.y,gy0.y,gz0.y);\r
    vec3 g010=vec3(gx0.z,gy0.z,gz0.z);\r
    vec3 g110=vec3(gx0.w,gy0.w,gz0.w);\r
    vec3 g001=vec3(gx1.x,gy1.x,gz1.x);\r
    vec3 g101=vec3(gx1.y,gy1.y,gz1.y);\r
    vec3 g011=vec3(gx1.z,gy1.z,gz1.z);\r
    vec3 g111=vec3(gx1.w,gy1.w,gz1.w);\r
    \r
    vec4 norm0=taylorInvSqrt(vec4(dot(g000,g000),dot(g010,g010),dot(g100,g100),dot(g110,g110)));\r
    g000*=norm0.x;\r
    g010*=norm0.y;\r
    g100*=norm0.z;\r
    g110*=norm0.w;\r
    vec4 norm1=taylorInvSqrt(vec4(dot(g001,g001),dot(g011,g011),dot(g101,g101),dot(g111,g111)));\r
    g001*=norm1.x;\r
    g011*=norm1.y;\r
    g101*=norm1.z;\r
    g111*=norm1.w;\r
    \r
    float n000=dot(g000,Pf0);\r
    float n100=dot(g100,vec3(Pf1.x,Pf0.yz));\r
    float n010=dot(g010,vec3(Pf0.x,Pf1.y,Pf0.z));\r
    float n110=dot(g110,vec3(Pf1.xy,Pf0.z));\r
    float n001=dot(g001,vec3(Pf0.xy,Pf1.z));\r
    float n101=dot(g101,vec3(Pf1.x,Pf0.y,Pf1.z));\r
    float n011=dot(g011,vec3(Pf0.x,Pf1.yz));\r
    float n111=dot(g111,Pf1);\r
    \r
    vec3 fade_xyz=fade(Pf0);\r
    vec4 n_z=mix(vec4(n000,n100,n010,n110),vec4(n001,n101,n011,n111),fade_xyz.z);\r
    vec2 n_yz=mix(n_z.xy,n_z.zw,fade_xyz.y);\r
    float n_xyz=mix(n_yz.x,n_yz.y,fade_xyz.x);\r
    return 2.2*n_xyz;\r
}\r

float snoise(vec3 v)\r
{\r
    const vec2 C=vec2(1./6.,1./3.);\r
    const vec4 D=vec4(0.,.5,1.,2.);\r
    \r
    
    vec3 i=floor(v+dot(v,C.yyy));\r
    vec3 x0=v-i+dot(i,C.xxx);\r
    \r
    
    vec3 g=step(x0.yzx,x0.xyz);\r
    vec3 l=1.-g;\r
    vec3 i1=min(g.xyz,l.zxy);\r
    vec3 i2=max(g.xyz,l.zxy);\r
    \r
    
    
    
    
    vec3 x1=x0-i1+C.xxx;\r
    vec3 x2=x0-i2+C.yyy;
    vec3 x3=x0-D.yyy;
    \r
    
    i=mod289(i);\r
    vec4 p=permute(permute(permute(\r
      i.z+vec4(0.,i1.z,i2.z,1.))\r
      +i.y+vec4(0.,i1.y,i2.y,1.))\r
      +i.x+vec4(0.,i1.x,i2.x,1.));\r
      \r
      
      
      float n_=.142857142857;
      vec3 ns=n_*D.wyz-D.xzx;\r
      \r
      vec4 j=p-49.*floor(p*ns.z*ns.z);
      \r
      vec4 x_=floor(j*ns.z);\r
      vec4 y_=floor(j-7.*x_);
      \r
      vec4 x=x_*ns.x+ns.yyyy;\r
      vec4 y=y_*ns.x+ns.yyyy;\r
      vec4 h=1.-abs(x)-abs(y);\r
      \r
      vec4 b0=vec4(x.xy,y.xy);\r
      vec4 b1=vec4(x.zw,y.zw);\r
      \r
      
      
      vec4 s0=floor(b0)*2.+1.;\r
      vec4 s1=floor(b1)*2.+1.;\r
      vec4 sh=-step(h,vec4(0.));\r
      \r
      vec4 a0=b0.xzyw+s0.xzyw*sh.xxyy;\r
      vec4 a1=b1.xzyw+s1.xzyw*sh.zzww;\r
      \r
      vec3 p0=vec3(a0.xy,h.x);\r
      vec3 p1=vec3(a0.zw,h.y);\r
      vec3 p2=vec3(a1.xy,h.z);\r
      vec3 p3=vec3(a1.zw,h.w);\r
      \r
      
      vec4 norm=taylorInvSqrt(vec4(dot(p0,p0),dot(p1,p1),dot(p2,p2),dot(p3,p3)));\r
      p0*=norm.x;\r
      p1*=norm.y;\r
      p2*=norm.z;\r
      p3*=norm.w;\r
      \r
      
      vec4 m=max(.6-vec4(dot(x0,x0),dot(x1,x1),dot(x2,x2),dot(x3,x3)),0.);\r
      m=m*m;\r
      return 42.*dot(m*m,vec4(dot(p0,x0),dot(p1,x1),\r
      dot(p2,x2),dot(p3,x3)));\r
  }\r
  \r
  float grain(vec2 vUv,vec2 uResolution,float frame,float multiplier){\r
      vec2 mult=vUv*uResolution;\r
      float offset=snoise(vec3(mult/multiplier,frame));\r
      float n1=pnoise(vec3(mult,offset),vec3(1./vUv*uResolution,1.));\r
      return n1/2.+.5;\r
  }\r
  \r
  float grain(vec2 vUv,vec2 uResolution,float frame){\r
      return grain(vUv,uResolution,frame,2.5);\r
  }\r
  \r
  float grain(vec2 vUv,vec2 uResolution){\r
      return grain(vUv + cos(uTime),uResolution,0.);\r
  }\r
  \r
void main(){\r
    vec2 newUv=20.*(vUv.xy-uResolution);\r
    \r
    float len;\r
    \r
    for(int i=0;i<AMOUNT;i++){\r
        len=length(vec2(newUv.x,newUv.y));\r
        newUv.x=newUv.x-cos(newUv.y+sin(len))+cos(uTime/3.);\r
        newUv.y=newUv.y+sin(newUv.x+cos(len))+sin(uTime/3.);\r
    }\r
    \r
    float grainSize=1.1;\r
    float g=grain(vUv,uResolution/grainSize);\r
    vec3 color=vec3(g);\r
    gl_FragColor=vec4(color,1.);\r
    \r
    gl_FragColor=vec4(cos(len + g),cos(len + g),cos(len + g),1.);\r
}`
let vertex = glsl`
uniform float time;
varying vec2 vUv;
varying vec3 vPosition;
uniform vec2 pixels;
float PI = 3.141592653589793238;
void main() {
  vUv = uv;
  vPosition = position;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}`


class Sketch {
    constructor(options) {
      this.scene = new THREE.Scene();
      this.container = options.dom;
      this.width = this.container.offsetWidth;
      this.height = this.container.offsetHeight;
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
      this.renderer.setSize(this.width, this.height);
      this.renderer.setClearColor(0xeeeeee, 0); 
      this.renderer.physicallyCorrectLights = true;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
  
      this.container.appendChild(this.renderer.domElement);
  
      this.camera = new THREE.PerspectiveCamera(
        40,
        window.innerWidth / window.innerHeight,
        0.001,
        1000
      );
  
      // var frustumSize = 10;
      // var aspect = window.innerWidth / window.innerHeight;
      // this.camera = new THREE.OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, -1000, 1000 );
      this.camera.position.set(0, 0, 5);
      // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.time = 0;
  
      this.isPlaying = true;
      
      this.addObjects();
      this.initPost()
      this.resize();
      this.render();
      this.setupResize();
      // this.settings();
    }
  
    settings() {
      let that = this;
      this.settings = {
        progress: 0,
        mRefractionRatio: 1.02,
        mFresnelBias: 0.1,
        mFresnelScale: 4.,
        mFresnelPower: 2.,
      };
      this.gui = new GUI();
      this.gui.add(this.settings, "progress", 0, 1, 0.01);
      this.gui.add(this.settings, "mRefractionRatio", 0, 3, 0.01).onChange(()=>{
        this.mat.uniforms.mRefractionRatio.value = this.settings.mRefractionRatio
      });
      this.gui.add(this.settings, "mFresnelBias", 0, 3, 0.01).onChange(()=>{
        this.mat.uniforms.mFresnelBias.value = this.settings.mFresnelBias
      });
      this.gui.add(this.settings, "mFresnelScale", 0, 3, 0.01).onChange(()=>{
        this.mat.uniforms.mFresnelScale.value = this.settings.mFresnelScale
      });
      this.gui.add(this.settings, "mFresnelPower", 0, 3, 0.01).onChange(()=>{
        this.mat.uniforms.mFresnelPower.value = this.settings.mFresnelPower
      });
    }
  
    onMouseMove(e){

      let XPos=e.clientX,
      YPos=e.clientY;
      this.plane.rotation.y = - 1 + XPos / 1500
      this.plane.rotation.x =  -0.2 + YPos/ 2000
      this.smallSphere.rotation.y = YPos/ window.innerHeight 
      this.smallSphere.rotation.x =  XPos / window.innerWidth
  }

    initPost(){
      this.composer = new EffectComposer( this.renderer );
      this.composer.addPass( new RenderPass( this.scene, this.camera ) );
  
      // const effect1 = new ShaderPass( DotScreenShader );
      // effect1.uniforms[ 'scale' ].value = 0;
      // this.composer.addPass( effect1 );
    }
  
    setupResize() {
      window.addEventListener("resize", this.resize.bind(this));
      window.addEventListener("mousemove",e=>{this.onMouseMove(e)})
    }
  
    resize() {
      this.width = this.container.offsetWidth;
      this.height = this.container.offsetHeight;
      this.renderer.setSize(this.width, this.height);
      this.composer.setSize(this.width, this.height);
      this.camera.aspect = this.width / this.height;
      
  
      // image cover
      this.imageAspect = 1080/1920;
      let a1; let a2;
      if(this.height/this.width>this.imageAspect) {
        a1 = (this.width/this.height) * this.imageAspect ;
        a2 = 1;
      } else{
        a1 = 1;
        a2 = (this.height/this.width) / this.imageAspect;
      }
  
      this.camera.updateProjectionMatrix();
  
  
    }
  
    addObjects() {
  
      this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256,{
          format: THREE.RGBAFormat,
          generateMipmaps: true,
          minFilter: THREE.LinearMipMapLinearFilter,
          encoding: THREE.sRGBEncoding
        }
      )
  
      this.cubeCamera = new THREE.CubeCamera(0.1,10,this.cubeRenderTarget)
  
      let that = this;
      this.material = 
      new THREE.ShaderMaterial({
        extensions: {
          // derivatives: "#extension GL_OES_standard_derivatives : enable"
        },
        side: THREE.DoubleSide ,
        uniforms:{
          uResolution:{
              value:{ x: 1920, y: 1080}
          },
          uTime:{value:0},
          R:{value:0},
          G:{value:0},
          B:{value:0}
      }
        // uniforms: {
        //   time: { value: 0 },
        //   resolution: { value: new THREE.Vector4() },
        // }
        ,
        // wireframe: true,
        transparent: true,
        vertexShader: vertex,
        fragmentShader: fragment
      });
  
      this.geometry = new THREE.SphereBufferGeometry(1.7, 32,32);
  
      this.plane = new THREE.Mesh(this.geometry, this.material);
      this.plane.position.z = 0
      this.plane.position.x = 0
      this.plane.position.y = 0
      this.scene.add(this.plane);

      // this.plane.visible = false
  
  
  
      let geo = new THREE.SphereBufferGeometry(1.5,32,32);
      this.mat = new THREE.ShaderMaterial({
        extensions: {
          // derivatives: "#extension GL_OES_standard_derivatives : enable"
        },
        side: THREE.DoubleSide,
        uniforms: {
          time: { value: 0 },
          tCube: { value: 0 },
          mRefractionRatio: {value: 1.02},
          mFresnelBias: {value: 0.1},
          mFresnelScale: {value: 1.7},
          mFresnelPower: {value: 0.2},
          resolution: { value: new THREE.Vector4() },
        },
        // wireframe: true,
        // transparent: true,
        // vertexShader: vertex1,
        // fragmentShader: fragment1
      });
  
      this.smallSphere = new THREE.Mesh(geo,this.mat)
      this.smallSphere.position.z = 0
      this.smallSphere.position.x = 0
      this.smallSphere.position.y = 0
      // this.smallSphere.position.z = 
      // this.scene.add(this.smallSphere)
    }
  
    stop() {
      this.isPlaying = false;
    }
  
    play() {
      if(!this.isPlaying){
        this.isPlaying = true;
        this.render()
      }
    }
  
    render() {
      if (!this.isPlaying) return;
      this.time += 0.025;
      this.smallSphere.visible = false;
      this.cubeCamera.update(this.renderer,this.scene);
      this.smallSphere.visible = true;
      this.mat.uniforms.tCube.value = this.cubeRenderTarget.texture
      this.material.uniforms.uTime.value = this.time;
      requestAnimationFrame(this.render.bind(this));
      // this.renderer.render(this.scene, this.camera);
      this.composer.render(this.scene, this.camera);
    }
}

const texts = ['Permissionless','Democratic','Transparent'];
var count = 0;
var index = 0;
var decrement = 0;
var currentText = '';
var letter = '';

function sleep(delay){
  return new Promise(resolve => setTimeout(resolve,delay));
}

const typeWrite = async() =>{
  if (count === texts.length)
  {
    count = 0;
  }
  let currentWord = texts[count];
  let currentLetter = currentWord.slice(0,++index);
  document.querySelector(".typing-mobile").textContent = currentLetter;
  if(index === currentWord.length)
  {
    await sleep(1500);
    while(index > 0)
    {
      currentLetter = currentWord.slice(0,--index);
      document.querySelector(".typing-mobile").textContent = currentLetter;
      await sleep(50);
    }
    count++;
    index = 0;
    await sleep(500);
  }
  setTimeout(typeWrite,Math.random()*200+50);
}
// typeWrite();


export default function LendingMobile() {


  useEffect(() => {
    new Sketch({
      dom: document.querySelector(".sphere__container-mobile")
    });

    function sleep(delay){
      return new Promise(resolve => setTimeout(resolve,delay));

    }

    const typeWrite = async() =>{
      if (count === texts.length)
      {
        count = 0;
      }
      let currentWord = texts[count];
      let currentLetter = currentWord.slice(0,++index);
      document.querySelector(".typing-mobile").textContent = currentLetter;
      if(index === currentWord.length)
      {
        await sleep(1500);
        while(index > 0)
        {
          currentLetter = currentWord.slice(0,--index);
          document.querySelector(".typing-mobile").textContent = currentLetter;
          await sleep(50);
        }
        count++;
        index = 0;
        await sleep(500);
      }
      setTimeout(typeWrite,Math.random()*200+50);
    }
    typeWrite();

  },[])

  return (
      <div
          className="landing-page-mobile"

      >
        <div>
          <div style={{margin: '0 auto'}}>

            <div className="title-container-mobile">

              <h2 className="title" style={{marginLeft:'0px', fontSize:'42px'}}>
                GLOBAL
              </h2>
              <h2 className="title" style={{fontSize:"42px"}}>
                PREDICTION
              </h2>
              <h2 className="title" style={{fontSize:"42px"}}>
                MARKETS
              </h2>
              <div className="sphere__container-mobile" >
                {/*<div  className="sphereCont" id="sphereCont"></div>*/}
              </div>


              <div className="subtitle-container-mobile" >
                {/*<Typer />*/}
                <p className="subtitle-mobile" style={{marginTop:'-22px'}}>
                  <span style={{margin:'0 auto'}}>
                  <span className="typing-mobile" style={{fontSize:"42px"}}>DEMOCRATIC</span>
                  <span className="blinking-cursor" style={{fontSize:"42px"}}>_</span>
                    </span>
                </p>
              </div>



            </div>


            <div style={{ padding:'0 16px'}}>
              <div className="contact-list__wrap">

                <div className="contact-wrap">
                  <h3 className="contact__title">
                    Email:
                  </h3>
                  <ul className="contact__button-list">
                    <li className="contact__button-item">
                      <a className="contact__button-link" href="mailto:info@wsb.sh">info@wsb.sh</a>
                    </li>
                  </ul>
                </div>
                <div className="contact-wrap">
                  <h3 className="contact__title">
                    Telegram:
                  </h3>
                  <ul className="contact__button-list">
                    <li className="contact__button-item">
                      <a className="contact__button-link" href="https://t.me/wsbsh">@wsbsh</a>
                    </li>
                  </ul>
                </div>


              </div>
              
            </div>


          </div>




        </div>
      </div>
  );
}