import React, { Suspense, useRef, useEffect } from "react";
import "./styleFooter.scss";

import Title from 'react-vanilla-tilt'

import realWorldEvents from './images/RealWorldEvents.png'
import predictionPools from './images/PredictionPools.png'
import metaverseReady from './images/Metaverse.png'
import trustless from './images/trustless.png'
import verifiedDataFeeds from './images/VerifiedData.png'
import crossChain from './images/cross-chain.png'


export default function Footer() {
  return (
    <div
      className="footer"
          >

      <div data-scroll
           data-scroll-direction="horizontal"
           data-scroll-speed="-2" // -6
           data-scroll-class="appear"
           style={{zIndex: '-10001'}}
      >

        <h2

            className="footer__title-wsb"
        >
          WSB.sh
          <br />
          <h2 data-scroll
              data-scroll-direction="horizontal"
              data-scroll-speed="1" // -1
              data-scroll-class="appear"
              className="footer__subtitle-ext"
        >Platform</h2>

        </h2>

        {/*<h2*/}
        {/*    style={{marginLeft:'80vw'}}*/}
        {/*>*/}
        {/*aaa*/}
        {/*</h2>*/}


      </div>

      {/*<h2 data-scroll*/}
      {/*    data-scroll-direction="horizontal"*/}
      {/*    data-scroll-speed="-7" // 7 15*/}
      {/*    data-scroll-class="appear"*/}
      {/*    className="footer__title-platform"*/}
      {/*>*/}
      {/*  Wsb Platform*/}
      {/*</h2>*/}


      {/*<div className="contact-addresses">*/}
      {/*<div>*/}

        <div
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-10"
            data-scroll-class="appear"
            data-scroll-repeat="true"
            // style={{background:'radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(40, 38, 32, 0) 89.58%, rgba(49, 45, 35, 0.273) 100%);'}}
            // className="lay-under"
        >

        {/*<div className="gallery-desktop"*/}
        {/*    data-scroll*/}
        {/*    data-scroll-direction="vertical"*/}
        {/*    data-scroll-speed="16" // 16 22*/}
        {/*    data-scroll-class="appear"*/}
        {/*    data-scroll-repeat="true"*/}
        {/*>*/}



          <div
              data-scroll
              data-scroll-direction="vertical"
              data-scroll-speed="22" // 16 22
              // data-scroll-class="appear"
              data-scroll-repeat="true"

              style={{display:'flex',
                flexDirection:'row',
                // marginLeft: '0vw',
                justifyContent:'space-around',
                width: '100vw',
                // paddingTop:'200px'
                paddingTop:'26vh'

              }}
          >




          {/*// FIRST COLUMN*/}
          <div data-scroll
               data-scroll-direction="vertical"
               data-scroll-speed="-2" // 22
               data-scroll-class="appear"
               data-scroll-repeat="true"
              style={{display:'flex', flexDirection:'column', marginTop:'90vh'}}
               // style={{display:'flex', flexDirection:'column',marginTop:'100px', marginRight:'200px', marginLeft: '100px'}}

          >
            <div>
              <Title style={{background:'rgba(17,17,17,0)'}}>
              <img src={realWorldEvents} alt="realWorldEvents" style={{width:"450px", height:"auto"}}/>
              </Title>
            </div>

            <div style={{marginTop:'400px'}}>
            {/*<h1 className="h1-pictures">Trustless</h1>*/}
              <Title style={{background:'rgba(17,17,17,0)'}}>
                <img src={predictionPools} alt= "predictionPools" style={{width:"450px", height:"auto"}}/>
              </Title>
            </div>
          </div>



            {/*SECOND COLUMN*/}
          <div data-scroll
              data-scroll-direction="vertical"
              data-scroll-speed="-1" // 22
              data-scroll-class="appear"
              data-scroll-repeat="true"
              style={{display:'flex', flexDirection:'column',

                // marginTop: '500px'
                marginTop: '52vh'

              }}
               // style={{display:'flex', flexDirection:'column', marginTop: '0px', marginRight: '200px'}}

          >

            <div>
            {/*<h1 style={{font:'EB Garamond sans-serif'}}>Prediction Pools</h1>*/}
              <Title style={{background:'rgba(17,17,17,0)'}}>
              <img src={metaverseReady} alt="metaverseReady" style={{width:"450px", height:"auto"}}/>
              </Title>
            </div>

            <div style={{marginTop:'400px'}}>
              <Title style={{background:'rgba(17,17,17,0)'}}>
              <img src={trustless} style={{width:"450px", height:"auto"}}/>
              </Title>
            </div>



          {/*    THIRD COLUMN*/}
          </div>
            <div style={{display:'flex', flexDirection:'column',
              // marginTop: '1100px'
              marginTop: '135vh'

            }}>

              <div>
                <Title style={{background:'rgba(17,17,17,0)'}}>
                <img src={verifiedDataFeeds} alt="verifiedDataAnalysis" style={{width:"450px", height:"auto"}}/>
                </Title>
              </div>

              <div style={{marginTop:'400px'}}>
                <Title style={{background:'rgba(17,17,17,0)'}}>
                <img src={crossChain} alt="crossChain" style={{width:"450px", height:"auto"}}/>
                </Title>
              </div>

            </div>

          </div>
        </div>


          {/*<div></div>*/}
        </div>
      // </div>
    // </div>
  );
}
