import React from "react";
import "./stylePhoneChart.scss";

// import Donut from './Donut';

import Chart from './chart.svg'


export default function PhoneChartMobile() {
  return (


      <div className="phonechart__mobile-wrap">

        {/*contact-addresses*/}

          <h1 className="wsb__title-percentage-mobile">Percentage Allocation </h1>

          {/*position:'absolute', marginTop: '-500px', marginLeft:'-40px'*/}
          <img src={Chart} style={{
              width: '360px',
              maxWidth: '100%',
              height:'auto',
              marginTop: '33px',
              marginBottom: '50px',
              padding: '0 16px',
              boxSizing: 'border-box'
          }} />

          <h2 className="wsb__title" style={{margin: '0 auto'}}>TOTAL MAXIMUM SUPPLY</h2>

          <h1 className="wsb__number" style={{margin: '0 auto'}}> 5 000 000 000</h1>
          <h2 className="wsb__title-currency" style={{color:'#847979'}}>$WSBT</h2>

      </div>
  );
}
